class Vector2 {
    constructor(x, y) {
        this.reset = (x, y) => {
            this.x = x;
            this.y = y;
            return this;
        };
        this.toString = (decPlaces) => {
            decPlaces = decPlaces || 3;
            const scalar = Math.pow(10, decPlaces);
            return `${Math.round(this.x * scalar) / scalar}, ${Math.round(this.y * scalar) / scalar}]`;
        };
        this.clone = () => {
            return new Vector2(this.x, this.y);
        };
        this.copyTo = (v) => {
            v.x = this.x;
            v.y = this.y;
        };
        this.copyFrom = (v) => {
            this.x = v.x;
            this.y = v.y;
        };
        this.magnitude = () => {
            return Math.sqrt(this.x * this.x + this.y * this.y);
        };
        this.magnitudeSquared = () => {
            return this.x * this.x + this.y * this.y;
        };
        this.normalise = () => {
            const m = this.magnitude();
            this.x = this.x / m;
            this.y = this.y / m;
            return this;
        };
        this.reverse = () => {
            this.x = -this.x;
            this.y = -this.y;
            return this;
        };
        this.plusEq = (v) => {
            this.x += v.x;
            this.y += v.y;
            return this;
        };
        this.plusNew = (v) => {
            return new Vector2(this.x + v.x, this.y + v.y);
        };
        this.minusEq = (v) => {
            this.x -= v.x;
            this.y -= v.y;
            return this;
        };
        this.minusNew = (v) => {
            return new Vector2(this.x - v.x, this.y - v.y);
        };
        this.multiplyEq = (scalar) => {
            this.x *= scalar;
            this.y *= scalar;
            return this;
        };
        this.multiplyNew = (scalar) => {
            const returnvec = this.clone();
            return returnvec.multiplyEq(scalar);
        };
        this.divideEq = (scalar) => {
            this.x /= scalar;
            this.y /= scalar;
            return this;
        };
        this.divideNew = (scalar) => {
            const returnvec = this.clone();
            return returnvec.divideEq(scalar);
        };
        this.dot = (v) => {
            return this.x * v.x + this.y * v.y;
        };
        this.angle = (useDegrees) => {
            return (Math.atan2(this.y, this.x) * (useDegrees ? Vector2Const.TO_DEGREES : 1));
        };
        this.rotate = (angle, useDegrees) => {
            const cosRY = Math.cos(angle * (useDegrees ? Vector2Const.TO_RADIANS : 1));
            const sinRY = Math.sin(angle * (useDegrees ? Vector2Const.TO_RADIANS : 1));
            Vector2Const.temp.copyFrom(this);
            this.x = Vector2Const.temp.x * cosRY - Vector2Const.temp.y * sinRY;
            this.y = Vector2Const.temp.x * sinRY + Vector2Const.temp.y * cosRY;
            return this;
        };
        this.equals = (v) => {
            return this.x === v.x && this.y === v.y;
        };
        this.isCloseTo = (v, tolerance) => {
            if (this.equals(v))
                return true;
            Vector2Const.temp.copyFrom(this);
            Vector2Const.temp.minusEq(v);
            return Vector2Const.temp.magnitudeSquared() < tolerance * tolerance;
        };
        this.rotateAroundPoint = (point, angle, useDegrees) => {
            Vector2Const.temp.copyFrom(this);
            Vector2Const.temp.minusEq(point);
            Vector2Const.temp.rotate(angle, useDegrees);
            Vector2Const.temp.plusEq(point);
            this.copyFrom(Vector2Const.temp);
        };
        this.isMagLessThan = (distance) => {
            return this.magnitudeSquared() < distance * distance;
        };
        this.isMagGreaterThan = (distance) => {
            return this.magnitudeSquared() > distance * distance;
        };
        this.dist = (v) => {
            return this.minusNew(v).magnitude();
        };
        this.x = x || 0;
        this.y = y || 0;
    }
}
const Vector2Const = {
    TO_DEGREES: 180 / Math.PI,
    TO_RADIANS: Math.PI / 180,
    temp: new Vector2(),
};
export default Vector2;
